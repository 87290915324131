<template>
  <v-dialog
    v-model="mostrar"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title>
          Gere uma ocorrência a partir de um PDF
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('input', false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4">
        <validation-observer ref="observer">
          <validation-provider
            v-slot="{ errors }"
            vid="arquivo"
          >
            <v-file-input
              v-model="arquivo"
              show-size
              label="Arquivo "
              dense
              outlined
              truncate-length="40"
              hide-details="auto"
              accept="application/pdf"
              :error-messages="errors"
            />
          </validation-provider>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="carregando"
          outlined
          @click="importar"
        >
          <v-icon
            left
            v-text="'mdi-upload'"
          />
          Importar arquivo
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ocorrenciasApi from '@/api/ocorrencias'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        carregando: false,
        arquivo: null,
      }
    },

    computed: {
      mostrar: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {

      async importar () {
        try {
          this.carregando = true

          const formData = new FormData()
          formData.append('arquivo', this.arquivo)
          formData.append('nome_arquivo', this.arquivo.name)

          await ocorrenciasApi.assimilarBoletim(formData)

          this.mostrar = false
          this.$emit('importado')
          this.$snackbar.mostrar({ cor: 'success', mensagem: 'Importado com sucesso' })
        } catch (e) {
          console.log(e)
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$erroApi.validacao(e))
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

    },

  }
</script>
